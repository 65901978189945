
/* BREADCRUMB (desativado)*/
    .breadcrumb {
        font-size: 12px;
        background-color: var(--gray-light);
    }
    .breadcrumb-item a{
        color: #6c757d;
    }
    ul.breadcrumb {
        padding: 10px 16px;
        list-style: none;
    }
    ul.breadcrumb li {
        display: inline;
    }
    ul.breadcrumb li+li:before {
        padding: 8px;
        color: black;
        content: "/\00a0";
    }
    ul.breadcrumb li a {
        color: #0275d8;
        text-decoration: none;
    }
    ul.breadcrumb li a:hover {
        color: #01447e;
        text-decoration: underline;
    }
    .breadcrumb-item.active {
        color: #6c757d;
        font-weight: 600;
    }
    .breadcrumb-item+.breadcrumb-item::before {
        float: left;
        padding-right: 0.5rem;
        color: var(--secondary);
        content: ">";
    }
/* end BREADCRUMB */

/* BOOTSTRAP BREADCRUMB*/

/* end BOOTSTRAP BREADCRUMB*/