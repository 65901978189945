textarea{
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:focus, textarea:focus {
    color: #495057;
    background-color: #fff;
    border-color: #495057;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(73,80,87,.25);
}
@media (max-width: 576px){
    .modal-dialog {
        max-width: 320px;
        margin: 0.5rem auto;
    }
}

/* APP FORM */
/*
#app_form .control-label{
    padding: 0 10px;
    position: absolute;
    top: -6px;
    background-color: white;
    left: 30px;
    font-size: 0.7rem;
    font-weight: 700;
    z-index: 100;
}
.custom-file-label {
    width: 98%;
    border-radius: 0;
    border-color: var(--blue-light);
    cursor: pointer;
    left: 15px;
}
.custom-file-label:after {
    content: "Carregar";
    border-radius: 0;
    border-color: var(--blue-light);
    color: #FFFFFF;
    background-color: var(--blue-light);
    cursor: pointer;
}

#app_form button.remove-file{
    border: 2px solid var(--body);
    border-radius: 50%;
    background-color: white;
    font-size: 11px;
}

.overflow-input{
    inline-size: 98%;
    overflow: hidden;
    top: 0px;
    padding-top: 8px;
}
*/

