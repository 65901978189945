footer {
	background: var(--gray-light);
	font-weight: 500;
}

footer a {
	color: var(--main-color);
	text-decoration: none;
	/*padding-bottom: 2px;
	margin-bottom: 4px;*/
	border-bottom: rgba(255, 255, 255, 0) solid 1px;
	transition: all .25s ease-out;
}
footer a:hover {
	color: var(--primary);
	text-decoration: underline;
	border-bottom: rgba(255, 255, 255,.5) solid 1px;
}

footer a i:hover {
	text-decoration: none;
}
footer h5 {
	font-size: 1rem;
	font-weight: 600;
	line-height: inherit;
}
footer h6 {
	/*font-weight: 400;*/
	line-height: inherit;
	font-size: 0.875rem;
}
footer .address a {
	display: inline-block;
}
/*footer .address {
	cursor: default;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}
footer .h6, footer h6 {
    font-size: 1.875rem;
	font-weight: bold;
	line-height: 1.5;
}

footer span, footer a {
	font-size: 0.875rem;
	display: flex;
	align-items: baseline;
}
footer a:hover {
    text-decoration: none;
    border-bottom: rgba(255, 255, 255,0) solid 1px;
}

footer .redes a:hover {
	text-decoration: none;
    border-bottom: rgba(255, 255, 255,0) solid 1px;
    transform: scale(1.025);
    filter: drop-shadow(2px 2px 2px #011f2f);
}*/

footer .social-media {
	display: flex;
	/*justify-content: flex-start;
	column-gap: 15px;*/
}

footer span.logosfooter {
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
}
/*span.logos, footer span.logosfooter.redes {
    justify-content: flex-start;
    column-gap: 15px;
}*/
footer .copyright span {
    font-size: 12px;
    /*display: inherit;*/
}

.current_bottom{
    text-decoration: underline;
}

img.light-logo.svg-logo.img-fluid {
	filter: brightness(0) invert(1);
}

.bg-color-body{
    background-color: var(--blue-dark);
}
.bg-color-dark{
    background-color: var(--gray-dark);
}

.footer-bar {
	background-color: var(--gray-dark);
	/* font-size: .75rem; */
	align-items: center;
}

.footer-bar .copyright li span {
    font-size: 12px;
    /*display: inline-flex;
    align-items: center;*/
}

.footer-bar a {
	color: #e03a3c;
	padding-bottom: 0;
    margin-bottom: 0;
	border-bottom: rgba(255, 255, 255, 0) solid 1px;
}
.footer-bar a:hover {
	border-bottom: rgba(255, 255, 255, 0) solid 1px;
}

/*.copyright {
	line-height: 1.25;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}*/


@media print {
	.footer-bar {
		position: fixed;
		bottom: 0;
		width: 100%;
		border-top: 1px solid transparent; /* for demo */
		margin-top: 30px;
	}
}