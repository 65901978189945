textarea{
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:focus, textarea:focus {
    color: #495057;
    background-color: #fff;
    border-color: #495057;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(73,80,87,.25);
}

/* APP FORM */
#app_form .control-label{
    padding: 0 10px;
    position: absolute;
    top: -6px;
    background-color: white;
    left: 30px;
    font-size: 0.7rem;
    font-weight: 700;
    z-index: 100;
}
.custom-file-label {
    width: 98%;
    border-color: #ced4da; 
    cursor: pointer;
    left: 15px;
}
.custom-file-label:after {
    content: "Carregar";
    border-radius: 0;
    border-color: gray;
    color: #FFFFFF;
    background-color: gray;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: .75px;
}

#app_form button.remove-file{
    border: 2px solid var(--body);
    border-radius: 50%;
    background-color: white;
    font-size: 11px;
}

.overflow-input{
    inline-size: 98%;
    overflow: hidden;
    /* top: 0px; */
    padding-top: 8px;
}


#modal_form .btn-send, #modal_form .btn-cancel {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.75px;
    transition: all 0.3s ease 0s;
    display: inline-flex;
    justify-content: center;
    align-items: baseline;
    margin: 0;
    padding: .375rem .75rem .375rem 1.25rem;
    color: var(--gray-light);
    background-color: var(--blue-dark);
}

#modal_form .btn-cancel {
    color: var(--main-color);
    background-color: var(--gray-light);
}

#modal_form .btn-send:hover {
    text-decoration: none;
    background-color: #00283c;
    box-shadow: 0 .125rem 0.5rem rgba(31, 31, 31, 0.25)!important;
}
#modal_form .btn-cancel:hover {
    text-decoration: none;
    background-color: #f8f9fa;
    box-shadow: 0 .125rem 0.5rem rgba(0,0,0,.15)!important;
}

#modal_form .btn > .bi.bi-chevron-right, #modal_form .btn > .bi.bi-x-lg {
    margin-left: 5px;
    color: var(--orange-dark);
    transition: all 0.4s ease-out;
}

.modal-footer {
    column-gap: 1em;
}