@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap);
body {
	font-family: 'Poppins', sans-serif;
}


#slider .swiper-slide{
    width: 100%;
    /*height: calc(85vh);*/
    height: calc(100vh - 135.5px);
    /*margin-top: 10rem;*/
}

.swiper, .swiper-container {
    width: 100%;
	/*height: 100%;*/
}

.swiper-button-next{
    /*background-image: none;
    right: -20px;
    left: auto;*/
    right: 30px !important;
    outline: none;
    color: var(--white) !important;
}
.swiper-button-prev {
    /*background-image: none;
    left: -20px;
    right: auto;*/
    left: 30px !important;
    outline: none;
    color: var(--white) !important;
}

#slider .swiper-pagination-bullet {
    width: 30px !important;
    height: 6px !important;
    background: var(--white) !important;
    opacity: 0.85 !important;
    margin: 0 4px !important;
    border-radius: 10px;
    border: none;
}

#slider .swiper-pagination-bullet-active {
    background: var(--orange) !important;
    border: none;
}

.swiper-pagination-bullet {
    border: 2px solid #de7612;
}
.swiper-pagination-bullet-active {
    background-color: var(--orange) !important;
    opacity: 1 !important;
    outline: none;
}

/* TESTEMUNHOS */
    #opinion .swiper-pagination-bullet-active, #noticias .swiper-pagination-bullet-active, #insights .swiper-pagination-bullet-active{
        background-color: var(--body);
        /*margin: 0 5px;*/
    }
    #opinion .swiper-pagination-bullet, #noticias .swiper-pagination-bullet, #insights .swiper-pagination-bullet{
        margin: 0 4px;
    }
    #opinion .swiper-pagination-bullet, #noticias .swiper-pagination-bullet, #insights .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 50%;
        background: transparent;
        opacity: 1;
        border: 1px solid #de7612;
        transition: opacity .3s;
    }

/* end TESTEMUNHOS */

.swiper-pagination-cartaz {
    position: absolute;
    text-align: center;
    transition: .3s opacity;
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
    z-index: 10;
}

#slider .swiper-button-next:after,
#slider .swiper-button-prev:after {
	font-size: 40px;
}

/* CAPTIONS */
    .caption_top_center {
        position: absolute;
        top: 25%;
        right: 50%;
        left: 50%;
        width: 75%;
        text-align: center;
        padding: 0;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .caption_top_left {
        position: absolute;
        top: 25%;
        left: 0;
        width: 50%;
        text-align: left;
        -webkit-transform: translate(25%, -50%);
        transform: translate(25%, -50%);
    }

    .caption_top_right {
        position: absolute;
        top: 25%;
        left: 0%;
        width: 50%;
        text-align: right;
        -webkit-transform: translate(75%, -50%);
        transform: translate(75%, -50%);
    }

    .caption_bottom_left {
        position: absolute;
        /*top: 50%;*/
        bottom: 0;
        left: 0;
        width: 50%;
        text-align: left;
        -webkit-transform: translate(25%, -50%);
        transform: translate(25%, -50%);
    }

    .caption_bottom_right {
        position: absolute;
        bottom: 0;
        left: 0%;
        width: 50%;
        text-align: right;
        -webkit-transform: translate(75%, -50%);
        transform: translate(75%, -50%);
    }

    .caption_bottom_center {
        position: absolute;
        right: auto;
        bottom: 0;
        left: 50%;
        width: 75%;
        text-align: center;
        padding: 0;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .caption_middle_left {
        position: absolute;
        top: 50%;
        bottom: auto;
        left: 0%;
        width: 50%;
        text-align: left;
        -webkit-transform: translate(25%, -50%);
        transform: translate(25%, -50%);
    }

    .caption_middle_right {
        position: absolute;
        top: 50%;
        bottom: auto;
        left: 0%;
        width: 50%;
        text-align: right;
        -webkit-transform: translate(75%, -50%);
        transform: translate(75%, -50%);
    }

    .caption_middle_center {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        width: 75%;
        text-align: center;
        padding: 0;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

.slide {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    font-size: 18px;
    background: #fff;
    overflow: hidden;
}

.swiper-pagination{
    bottom: 0;
    right: 0;
    left: 0;
}

/*.slide-image {
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
    position: absolute;
    left: -5%;
    right: 0;
    width: 110%;
    height: 100%;
    background-position: 50% 45%;
    background-size: cover;
}*/

.slide-image {
    /*position: absolute;*/
    right: 0;
    height: 100%;
    background-position: 50% 50%;

    /*width: 110% !important;
    left: -5% !important;
    border-bottom-left-radius: 35%;
    border-bottom-right-radius: 35%;*/
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    will-change: transform;
}
/*.slide-title {
    font-size: 3vw;
    font-weight: 900;
    line-height: 1.3;
    color: var(--white);
    max-width: 50%;
    white-space: normal;
    word-break: break-word;
    text-transform: none;
    text-shadow: rgb(0 0 0 / 40%) 1px 1px 1px;
    z-index: 100;
}*/

.cartaz-titulo, .cartaz-subtitulo {
    color: var(--white);
    font-size: 52px;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 1.2;
    margin: 0;
    margin-bottom: 1rem;
    text-shadow: 1px 2px 2px rgb(51 51 51 / 50%), 0px 0px 40px rgb(0 0 0 / 10%);
    z-index: auto;
    padding: 0px;
    -webkit-filter: none;
            filter: none;
    text-wrap: balance;
}
.cartaz-subtitulo {
    font-size: 	24px;
    line-height: 1em;
    text-transform: none;
    text-transform: initial;
    font-weight: 600;
    text-shadow: 1px 2px 2px rgb(51 51 51 / 50%), 0px 0px 20px rgb(0 0 0 / 10%);
}
.btn-left{
    position: absolute;
    top: 65%;
    left: 15%;
}

#slider .btn {    
    /* Border styles */
    border: 2px solid transparent;
    background-color: rgb(86 125 38 / 80%);
}
#slider .btn:hover {    
    /* Border styles */
    border: 2px solid transparent;
    background-color: rgb(86 125 38 / 100%);
}

/* BUTTON SCROLL SLIDER */
    .demo {
        position: absolute;
        bottom: 20px;
        left: 50%;
        z-index: 2;
        display: inline-block;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        color: var(--cinza);
        text-decoration: none;
        transition: opacity .3s;
    }
    #slider-btn {
        padding-top: 60px;
    }
    #slider-btn span {
        position: absolute;
        top: 0;
        left: 50%;
        width: 30px;
        height: 50px;
        margin-left: -15px;
        border: 2px solid var(--white);
        border-radius: 50px;
        box-sizing: border-box;
    }
    #slider-btn span::before {
        position: absolute;
        top: 10px;
        left: 50%;
        content: '';
        width: 6px;
        height: 6px;
        margin-left: -3px;
        background-color: var(--white);
        border-radius: 100%;
        -webkit-animation: sdb10 2s infinite;
        animation: sdb10 2s infinite;
        box-sizing: border-box;
    }

    @-webkit-keyframes sdb10 {
        0% {
            -webkit-transform: translate(0, 0);
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        80% {
            -webkit-transform: translate(0, 20px);
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }
    @keyframes sdb10 {
        0% {
            -webkit-transform: translate(0, 0);
                    transform: translate(0, 0);
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        80% {
            -webkit-transform: translate(0, 20px);
                    transform: translate(0, 20px);
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }


/* MEDIA QUERIES */

    @media only screen and (max-width : 1680px) {
        #slider .swiper-slide{
            height: calc(100vh - 116px);
        }
    }
    @media only screen and (max-width : 1180px) {
        #slider .swiper-slide{
            height: calc(100vh - 116px);
        }
    }
    @media only screen and (max-width : 992px) {
        #slider .swiper-slide {
            height: calc(100vh - 596px);
        }
        .cartaz-titulo {
            font-size: 32px;
            line-height: 1;
        }
        .cartaz-subtitulo {
            font-size: 1.75vh;
        }
    }
    @media only screen and (max-width : 768px) {
        #slider .swiper-slide{
            height: calc(100vh - 506px);
        }
        /*.cartaz-titulo {
            font-size: 3vh;
        }*/
        .caption_top_center, .caption_top_left, .caption_top_right,
        .caption_middle_center, .caption_middle_left, .caption_middle_right,
        .caption_bottom_center, .caption_bottom_left, .caption_bottom_right {
            text-align: center;
            position: absolute;
            top: 50%;
            right: auto;
            bottom: auto;
            left: 50%;
            width: 75%;
            text-align: center;
            padding: 0;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        /*.swiper-button-next,
            .swiper-button-prev { 
            display: none !important;;
        }*/
    }
    @media only screen and (max-width : 576px) {}

    @media only screen and (max-width : 375px) {
        #slider .swiper-slide {
            height: calc(100vh - 400px);
        }
    }

    /*.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 3vh !important;
    }*/

/* font-family: 'Poppins', sans-serif; */

	:root {
		--main-color: #444444;
		--color-dark: #252c30;
		--text-grey: #606060;
		--blue-dark: #013b5a;
		--blue-light: #7f9dac;
		--blue: #008AC9;
		--orange-dark: #DF760E;
		--orange-light: #EFBA86;
		--orange: #DF760E;
		--black: #282828;
		--white: #FFFFFF;
		--red: #DB001B;
		--green: #6ca66c;
		--yellow: #FFAA00;
		--gray-dark: #111111;
		--gray: #92a1b7;
		--gray-light: #fafafa;
		--body: #444444;
		--primary: #567D26;
		/*--secondary: #A5D21B;  original do logo*/
		--secondary: #79AA21;
	
		font-size: 16px;
	}

	html {
  -webkit-text-size-adjust: 100%;
		-moz-text-size-adjust: 100%;
		     text-size-adjust: 100%;
    }

	body {
		margin: 0;
		font-family: 'Poppins', sans-serif;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5715;
		letter-spacing: 0.25px;
		color: #444444;
		color: var(--main-color);
		cursor: default;
		text-rendering: optimizelegibility;
		text-wrap: pretty;
	}

	*:focus {
		outline: none !important;
		box-shadow: 0 0 0 0 transparent !important;
	}

	::selection {
		background: rgb(1 59 90 / 10%);
	}

	a {
		color: #79AA21;
		color: var(--secondary);
		overflow-wrap: break-word;
		word-wrap: break-word;
	}
	a:hover {
		color: #92a1b7;
		color: var(--gray);
	}

	a.color{
		color: #013b5a;
		color: var(--blue-dark);
	}
	a.color-red{
		color: #DB001B;
		color: var(--red);
	}
	a.color-orange{
		color: #79AA21;
		color: var(--secondary);
	}

	b, strong {
		font-weight: 600;
	}


	/*.div-text ul, .col-text ul, .div-text ol, .col-text ol {
		padding-inline-start: 20px;
		margin-bottom: 0.5rem;
	}*/
	.div-text ul li, .col-text ul li, .div-text ol li, .col-text ol li, .div-text ul li ul li, .col-text ul li ul li, .div-text ol li ol li, .col-text ol li ol li, .div-text ul li ol li, .col-text ul li ol li, .div-text ol li ul li, .col-text ol li ul li {
		padding-left: 0.5rem;
		padding-bottom: 0.25rem;
		/*margin-top: 0.25rem;*/
	}
	.div-text ul li ul li:first-child, .col-text ul li ul li:first-child, .div-text ul li ol li:first-child, .col-text ul li ol li:first-child, .div-text ol li ul li:first-child, .col-text ol li ul li:first-child, .div-text ol li ol li:first-child, .col-text ol li ol li:first-child {
		padding-top: 0.5rem;
	}
	.div-text ul li::marker, .col-text ul li::marker {
		content: "\2756";
		color: #79AA21;
		color: var(--secondary);
	}
	.div-text ul li ul li::marker, .col-text ul li ul li::marker {
		content: "\2666";
		color: #79AA21;
		color: var(--secondary);
	}
	.div-text ol li::marker, .col-text ol li::marker {
		color: #79AA21;
		color: var(--secondary);
		font-weight: 600;
	}


	/* MARGIN PADDING */
	    .mt-100 {
	        margin-top: 100px;
	    }
	    .py-100 {
	        padding-top: 100px;
	        padding-bottom: 100px;
	    }
	    #destaque.py-100 {
	        padding-top: 85px;
	        padding-bottom: 85px;
	    }
	/* end MARGIN PADDING */

	.btn {
		font-size: 0.75rem;
		font-weight: 500;
		line-height: 1;
		letter-spacing: 0.75px;
		text-decoration: none;
		border-radius: 40px;
		padding: 0.75rem 1.5rem;
		display: inline-flex;
		justify-content: center;
		align-items: baseline;
		transition: all 0.3s ease 0s !important;
	}
	#project .btn, #destaque .btn, #opinion .btn, #newsletter .btn {
		font-weight: 500;
		letter-spacing: 0.75px;
	}
	.btn-primary,
	.btn-primary:not(:disabled):not(.disabled).active,
	.btn-primary:not(:disabled):not(.disabled):active,
	.show>.btn-primary.dropdown-toggle,
	.btn-primary-inv,
	.btn-primary-inv:not(:disabled):not(.disabled).active,
	.btn-primary-inv:not(:disabled):not(.disabled):active,
	.show>.btn-primary-inv.dropdown-toggle {
		background-color: #567d26 !important;
		border: 1px solid #567d26 !important;
		color: #FFFFFF;
		color: var(--white);
		transition: all 0.3s ease 0s !important;
	}
	.btn-primary-inv,
	.btn-primary-inv:not(:disabled):not(.disabled).active,
	.btn-primary-inv:not(:disabled):not(.disabled):active,
	.show>.btn-primary-inv.dropdown-toggle {
		background-color: #304715 !important;
		border: 1px solid #304715 !important;
	}
	.btn-primary:hover, .btn-primary:active,
	.btn-primary.active, .open > .dropdown-toggle.btn-primary,
	.btn-primary:focus, .btn-primary.focus,
	.btn-primary-inv:hover, .btn-primary-inv:active,
	.btn-primary-inv.active, .open > .dropdown-toggle.btn-primary-inv,
	.btn-primary-inv:focus, .btn-primary-inv.focus {
		color: #FFFFFF !important;
		color: var(--white) !important;
		background-color: #304715 !important;
		border: 1px solid #304715 !important;
		outline: none;
		box-shadow: none;
	}
	.btn-primary-inv:hover, .btn-primary-inv:active,
	.btn-primary-inv.active, .open > .dropdown-toggle.btn-primary-inv,
	.btn-primary-inv:focus, .btn-primary-inv.focus {
		background-color: #567d26 !important;
		border: 1px solid #567d26 !important;
	}
	.btn-primary.disabled, .btn-primary:disabled.btn-primary-inv.disabled, .btn-primary-inv:disabled{
	  background-color: #7f9dac;
	  background-color: var(--blue-light);
	  border: 2px solid #7f9dac;
	  border: 2px solid var(--blue-light);
	  color: #FFFFFF;
	  color: var(--white);
	}
	.btn-primary:not(:disabled):not(.disabled).active:focus,
	.btn-primary:not(:disabled):not(.disabled):active:focus,
	.show>.btn-primary.dropdown-toggle:focus {
	    box-shadow: 0 0 0 0rem rgba(0,123,255,.5);
	}
	.btn-secondary,
	.btn-secondary:not(:disabled):not(.disabled).active,
	.btn-secondary:not(:disabled):not(.disabled):active,
	.show>.btn-secondary.dropdown-toggle {
	    background-color: #015b8b;
	    border: 2px solid #015b8b;
	    color: #FFFFFF;
	    color: var(--white);
	}
	.btn-secondary:hover, .btn-secondary:active,
	.btn-secondary.active, .open > .dropdown-toggle.btn-secondary,
	.btn-secondary:focus, .btn-secondary.focus{
	    color: #013b5a;
	    color: var(--blue-dark);
	    background-color: #FFFFFF;
	    background-color: var(--white);
	    border: 2px solid #013b5a;
	    border: 2px solid var(--blue-dark);
	    outline: none;
	    box-shadow: none;
	}
	.btn-secondary.disabled, .btn-secondary:disabled{
	  background-color: #7f9dac;
	  background-color: var(--blue-light);
	  border: 2px solid #7f9dac;
	  border: 2px solid var(--blue-light);
	  color: #FFFFFF;
	  color: var(--white);
	}
	.btn-secondary:not(:disabled):not(.disabled).active:focus,
	.btn-secondary:not(:disabled):not(.disabled):active:focus,
	.show>.btn-secondary.dropdown-toggle:focus {
	    box-shadow: 0 0 0 0rem rgba(0,123,255,.5);
	}
	.btn-tertiary,
	.btn-tertiary:not(:disabled):not(.disabled).active,
	.btn-tertiary:not(:disabled):not(.disabled):active,
	.show>.btn-tertiary.dropdown-toggle  {
	    background-color: #79AA21;
	    background-color: var(--secondary);
	    border: 2px solid #79AA21;
	    border: 2px solid var(--secondary);
	    color: #FFFFFF;
	    color: var(--white);
	}
	.btn-tertiary:hover, .btn-tertiary:active,
	.btn-tertiary.active, .open > .dropdown-toggle.btn-tertiary,
	.btn-tertiary:focus, .btn-tertiary.focus{
		color: #FFFFFF;
		color: var(--white);
	    background-color: #EFBA86;
	    background-color: var(--orange-light);
	    border: 2px solid #EFBA86;
	    border: 2px solid var(--orange-light);
	    outline: none;
	    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
	}
	.btn-tertiary.disabled, .btn-tertiary:disabled{
	  background-color: #EFBA86;
	  background-color: var(--orange-light);
	  border: 2px solid #EFBA86;
	  border: 2px solid var(--orange-light);
	  color: #FFFFFF;
	  color: var(--white);
	}
	.btn-tertiary:not(:disabled):not(.disabled).active:focus,
	.btn-tertiary:not(:disabled):not(.disabled):active:focus,
	.show>.btn-tertiary.dropdown-toggle:focus {
	    box-shadow: 0 0 0 0rem rgba(0,123,255,.5);
	}

	#info .btn-primary {
	    background-color: #FFFFFF;
	    background-color: var(--white);
	    font-weight: 700;
	    border: 2px solid #FFFFFF;
	    border: 2px solid var(--white);
	    color: #013b5a;
	    color: var(--blue-dark);
	    border-radius: 30px;
	    padding: 5px 20px;
	}
	#info .btn-primary:hover, #info .btn-primary:active,
	#info .btn-primary.active, #info .open > .dropdown-toggle.btn-primary,
	#info .btn-primary:focus, #info .btn-primary.focus{
	    background-color: #79AA21;
	    background-color: var(--secondary);
	    border-color: #79AA21;
	    border-color: var(--secondary);
	    outline: none;
	    color: #FFFFFF;
	    color: var(--white);
	}

	.btn-link:hover{
	    color: #013b5a;
	    color: var(--blue-dark);
	    text-decoration: underline;
	}

	button.btn-link:hover,
	button.btn-link:focus{
	    text-decoration: none;
	}

	.btn-text, .btn-text-inv {
		font-weight: 500;
		letter-spacing: 0.75px;
		color: #444444;
		color: var(--main-color);
		transition: all 0.3s ease 0s;
		background-color: #fafafa;
		background-color: var(--gray-light);
		padding: 7px 14px;
		text-decoration: none;
		box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
	}
	.btn-text-inv {
		color: #fafafa;
		color: var(--gray-light);
		background-color: #444444;
		background-color: var(--main-color);
	}
	.btn-text:hover {
		color: #fafafa;
		color: var(--gray-light);
		background-color: #444444;
		background-color: var(--main-color);
	}
	.btn-text-inv:hover {
		color: #444444;
		color: var(--main-color);
		background-color: #fafafa;
		background-color: var(--gray-light);
	}
	.bi.bi-chevron-right, .bi.bi-chevron-left, .bi.bi-download {
		font-size: medium;
		font-size: initial;
		margin-left: 5px;
		color: #79AA21;
		color: var(--secondary);
		transition: all 0.4s ease-out;
	}
	.bi.bi-chevron-left {
		margin-left: 0;
		margin-right: 5px;
		color: #79AA21;
		color: var(--secondary);
	}
	/* .btn-text:hover > .bi.bi-chevron-right {
		margin-left: 10px;
	}
	.btn-text:hover > .bi.bi-chevron-left {
		margin-right: 10px;
	} */

	.btn-footer-card {
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.75px;
		color: #444444;
		color: var(--main-color);
		transition: all 0.3s ease 0s;
		display: inline-flex;
		justify-content: center;
		align-items: baseline;
		padding: 7px 18px;
		text-decoration: none;
	}
	.btn-footer-card > .bi.bi-download {
		font-size: medium;
		font-size: initial;
		margin-left: 5px;
		color: #79AA21;
		color: var(--secondary);
		transition: all 0.4s ease-out;
	}
	.btn-footer-card:hover {
		color: #79AA21;
		color: var(--secondary);
	}
	.accordion .btn{
	    background-color: transparent;
	}

	/*.accordion button.collapsed:before{
	    font-family: "Font Awesome 5 Free";
	    font-size: 0.7rem;
	    font-weight: 900; 
	    content: "\f067";
	    float: left;
	    margin-right: 15px;
	    color: white;
	    border-radius: 50%;
	    padding: 2.5px 6px;
	}
	.accordion button:before{
	    font-family: "Font Awesome 5 Free";
	    font-size: 0.7rem;
	    font-weight: 900; 
	    content: "\f068";
	    float: left;
	    margin-right: 15px;
	    color: white;
	    border-radius: 50%;
	    padding: 2.5px 6px;
	}
	.accordion button.btn-link.collapsed:before{
	    background-color: var(--blue-dark);
	}
	.accordion button.btn-link:before{
	    background-color: var(--blue-dark);
	}*/
	.accordion button.btn-link-red.collapsed:before{
	    background-color: #DB001B;
	    background-color: var(--red);
	}
	.accordion button.btn-link-red:before{
	    background-color: #DB001B;
	    background-color: var(--red);
	}
	.accordion button.btn-link-orange.collapsed:before{
		background-color: #79AA21;
		background-color: var(--secondary);
	}
	.accordion button.btn-link-orange:before{
	    background-color: #79AA21;
	    background-color: var(--secondary);
	}

	.titulo-primario, .titulo-secundario {
		font-size: 32px;
		font-weight: 700;
		line-height: 1.25;
		text-wrap: balance;
	}
	.titulo-secundario{
		font-size: 24px;
		color: #79AA21;
		color: var(--secondary);
		font-weight: 300;
	}
	.subtitulo-primario, .subtitulo-secundario, .content-list .titulo-primario{
		font-size: 24px;
		font-weight: 700;
		text-wrap: balance;
	}
	.subtitulo-secundario, .content-list .titulo-secundario{
		font-size: 18px;
		color: #79AA21;
		color: var(--secondary);
		font-weight: 400;
		line-height: 1.5;
	}
	.content-list .subtitulo-primario{
		font-size: 18px;
		text-wrap: balance;
	}
	.content-list .subtitulo-secundario{
		font-size: 16px;
		text-wrap: balance;
	}

	.legenda {
		font-size: .75em;
	}
	.maiusculas {
		text-transform: uppercase;
		font-weight: 500;
	}

	@media only screen and (max-width: 768px){
		.titulo-primario {
			font-size: 26px;
		}
		.titulo-secundario {
			font-size: 22px;
		}
		.subtitulo-primario, .content-list .titulo-primario{
			font-size: 20px;
		}
		.subtitulo-secundario .content-list .titulo-secundario{
			font-size: 18px;
		}
		.content-list .subtitulo-primario{
			font-size: 18px;
		}
		.content-list .subtitulo-secundario{
			font-size: 16px;
		}
	}

	blockquote {
		font-size: 1.2em;
		font-style: italic;
		letter-spacing: 0.5px;
		border-left: 2px solid #79AA21;
		border-left: 2px solid var(--secondary);
		background-color: #fafafa;
		background-color: var(--gray-light);
		margin: 20px 0 20px 20px;
		padding: 1rem 1rem 0;
		display: block;
		position: relative;
		overflow: hidden;
	}

	#opinion blockquote {
		font-style: normal;
		font-size: 0.875em;
		letter-spacing: normal;
		letter-spacing: initial;
		background-color: #FFFFFF;
		border-left: inherit;
		padding: 1.75rem;
		margin: 1.75rem;
		border: 4px double #79AA21;
		border: 4px double var(--secondary);
	}
	#opinion .testimonial-text { 
		font-size: 1.125rem;
		font-weight: 400;
		color: #FFFFFF;
		color: var(--white);
		letter-spacing: 1px;
	}

	/*#opinion p {
		margin-top: 0;
		margin-bottom: 0.2rem;
		display: inline;
	}*/

	#opinion .blockquote-footer {
		display: block;
		font-size: 18px;
		color: #111111;
		color: var(--gray-dark);
		font-weight: 700;
		background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
		background: initial;
    	box-shadow: none!important;
	}
	#opinion span{
		display: block;
		font-size: 0.75rem;
		color: #999999;
		font-weight: 400;
	}

	#opinion .blockquote-footer::before {
		content: "";
	}

	/*#opinion blockquote:before {
		content: '\201C';
		font-family: FontAwesome;
		color: var(--secondary);
		font-size: 7.5em;
		font-weight: 700;
		font-size: 4em;
		line-height: .1em;
		margin-right: 0.15em;
		vertical-align: -0.4em;
	}*/

	#opinion blockquote:before,
	#opinion blockquote:after {
		position: absolute;
		color: #79AA21;
		color: var(--secondary);
		font-size: 8rem;
		width: 4rem;
		height: 4rem;
	}

	#opinion blockquote:before {
		content: '';
		left: 1rem;
		top: -1.5rem;
	}

	/*#opinion blockquote:after {
		content: '”';
		right: -5rem;
		bottom: 1rem;
	}*/

	.limit-5-lines{
	    display: -webkit-box;
	    -webkit-line-clamp: 5;
	    -webkit-box-orient: vertical;
	    overflow: hidden;
	}
	.limit-10-lines{
	    display: -webkit-box;
	    -webkit-line-clamp: 10;
	    -webkit-box-orient: vertical;
	    overflow: hidden;
	}

	.column-count-2{
	    -webkit-column-count: 2;
	            column-count: 2;
	}
	@media (max-width: 576px) {
	    .column-count-2{
	        -webkit-column-count: 1;
	                column-count: 1;
	    }
	}

	.bg-color-gray-light{
	    background-color: #fafafa;
	    background-color: var(--gray-light);
	}
	.bg-color-gray{
	    background-color: #92a1b7;
	    background-color: var(--gray);
	}
	.bg-color-red{
	    background-color: #DB001B;
	    background-color: var(--red);
	}
	.bg-color-orange{
	    background-color: #79AA21;
	    background-color: var(--secondary);
	}
	.bg-color-blue{
	    background-color: #013b5a;
	    background-color: var(--blue-dark);
	}
	.bg-color-body{
	    background-color: #013b5a;
	    background-color: var(--blue-dark);
	}
	.bg-color-white{
	    background-color: #FFFFFF;
	    background-color: var(--white);
	}
	.bg-color-newsletter{
	    background-color: #fafafa;
	    background-color: var(--gray-light);
	}
	.border-blue{
	    border: 2px solid #013b5a;
	    border: 2px solid var(--blue-dark);
	}

	.parallax{
		position: relative;
		height: auto;
		background-position: right;
		background-attachment: fixed;
		background: center/cover;
		width: 100%;
	}

	.overlay-img {
	    background: rgba(0, 0, 0, 0.4);
	    width: 100%;
	    height: 100%;
	}

	/* figure .effect-apollo{
	    position: relative;
	    overflow: hidden;
	    min-width: 100%;
	    max-width: 480px;
	    height: auto;
	    background: #975FA3;
	    text-align: center;
	    cursor: pointer;
	} */


	.grid {
		position: relative;
		clear: both;
		margin: 0 auto;
		padding: 1em 0 4em;
		max-width: 1000px;
		list-style: none;
		text-align: center;
	}

	/* Common style */
	.grid figure {
		position: relative;
		float: left;
		overflow: hidden;
		/*margin: 10px 1%;
		min-width: 320px;
		max-width: 480px;
		max-height: 360px;*/
		width: 100%;
		height: auto;
		background: #3085a3;
		text-align: center;
		/* cursor: pointer; */
		aspect-ratio: auto 16/9;
	}

	.grid figure .img {
		position: relative;
		display: block;
		min-height: 100%;
		max-width: 100%;
		opacity: 0.8;
	}

	.grid figure figcaption {
		padding: 2em;
		color: #fff;
		text-transform: uppercase;
		font-size: 1.25em;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}

	.grid figure figcaption::before,
	.grid figure figcaption::after {
		pointer-events: none;
	}

	.grid figure figcaption,
	.grid figure figcaption > a {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.boxshadowtwobottom{
	    position: relative;
	}
	.boxshadowtwobottom:before, .boxshadowtwobottom:after{
	    z-index: -1;
	    position: absolute;
	    content: "";
	    bottom: 28px;
	    left: 22px;
	    width: 50%;
	    top: 80%;
	    max-width:300px;
	    background: #777;
	    box-shadow: 0 15px 10px #777;
	    -webkit-transform: rotate(-3deg);
	    transform: rotate(-3deg);
	}
	.boxshadowtwobottom:after{
	    -webkit-transform: rotate(3deg);
	    transform: rotate(3deg);
	    right: 22px;
	    left: auto;
	}

	/*-----------------*/
	/***** Apollo *****/
	/*-----------------*/

	figure.effect-apollo {
		background: #FFFFFF;
		background: var(--white);
	}

	figure.effect-apollo .img {
		opacity: 0.95;
		transition: opacity 0.35s, -webkit-transform 0.35s;
		transition: opacity 0.35s, transform 0.35s;
		transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
		-webkit-transform: scale3d(1,1,1);
		transform: scale3d(1,1,1);
	}

	figure.effect-apollo figcaption::before {
		position: absolute;
		top: 0;
		left: 0;
		width: 130%;
		height: 130%;
		background: rgba(255,255,255,0.5);
		content: '';
		transition: -webkit-transform 0.6s;
		transition: transform 0.6s;
		transition: transform 0.6s, -webkit-transform 0.6s;
		-webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
		transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
	}

	figure.effect-apollo p {
		position: absolute;
		right: 0;
		bottom: 0;
		margin: 3em;
		padding: 0 1em;
		max-width: 150px;
		border-right: 4px solid #fff;
		text-align: right;
		opacity: 0;
		transition: opacity 0.35s;
	}

	figure.effect-apollo h2 {
		text-align: left;
	}

	figure.effect-apollo:hover .img {
		opacity: 0.6;
		-webkit-transform: scale3d(1.05,1.05,1);
		transform: scale3d(1.05,1.05,1);
	}

	figure.effect-apollo:hover figcaption::before {
		-webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
		transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
	}

	figure.effect-apollo:hover p {
		opacity: 1;
		transition-delay: 0.1s;
	}


	.loader {
		margin: 0;
		position: fixed;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
	}

	.titulo-pp{
		font-size: 2rem;
		font-weight: 700;
	}
	#parceiros_list .row{
		align-items: center;
	}
	#parceiros_list .titulo-pp{
		text-transform: uppercase;
		font-size: 1.375rem;
		font-weight: 700;
	}

	.titulo-pp::after{
		height: 3px;
		width: 4rem;
		content: "";
		display: block;
		background-color: #79AA21;
		background-color: var(--secondary);
		margin: auto;
		margin-top: 0.75rem;
	}
	#destaque .titulo-pp::after{
		margin: 0;
		margin-top: 0.75rem;
	} 
	#newsletter .titulo-pp::after{
		margin: 0;
		margin-top: 15px;
		margin-bottom: 15px;
	}
	#newsletter .titulo-pp{
		font-size: 1.5rem;
	} 
	#insights .titulo-pp::after, #noticias .titulo-pp::after, #parceiros .titulo-pp::after, .routes-cards .titulo-pp::after{
		margin-top: 0.75rem;
		margin-bottom: 60px;
	}

	.insights-cards .titulo-pp::after, .news-cards .titulo-pp::after{
		margin-bottom: 2.5rem;
	}
	.subtitulo-pp{
		color: #444444;
		color: var(--main-color);
		font-weight: 700;
		font-size: 18px;
	}
	#insights .subtitulo-pp, #noticias .subtitulo-pp {
		font-weight: 700;
		margin-bottom: 0.75rem;
		line-height: 1.35;
	}
	#insights .text-pp p, #noticias .text-pp p {
		margin-bottom: 0;
	}
	#insights .text-pp, #noticias .text-pp {
		display: -webkit-box;
		text-overflow: ellipsis;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
		line-height: 1.5;
	}

	#opinion .titulo-pp::after{
		background-color: #FFFFFF;
		background-color: var(--white);
	}
	
	#insights-cards .card-title, #noticias-cards .card-title {
		color: #444444;
		color: var(--main-color);
		font-weight: 700;
		font-size: 18px;
		line-height: 1.35;
		margin-bottom: 0.75rem;
	}
	#insights-cards .card-text, #noticias-cards .card-text {
		line-height: 1.35;
		display: -webkit-box;
		text-overflow: ellipsis;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;		
	}

	#parceiros h3.titulo-pp::after{
		margin-top: 25px;
		margin-bottom: 25px;
	}
	#parceiros p {
		line-height: 22px;
	}

	#parceiros .col-image {
		width: 720px;
		height: auto;
		overflow: hidden;
	}

	@media only screen and (max-width : 576px) {
		.titulo-pp{
			font-size: 1.5rem;
			font-weight: 700;
		}
	}

/* DESTAQUE */
    #destaque .mp-mask{
        /*margin: 40px auto;*/
        padding-top: 10rem;
        padding-bottom: 10rem;
    }
    #destaque {
        /*background-image: url('https://cdn.assec.app/repo/amcb/website/static/assets/img/banner-destaque.jpg');*/
        background-size: cover;
    }
    #destaque::after{
        content: '';
        display: block;
        background-image: url('https://cdn.assec.app/repo/amcb/website/static/assets/img/banner-mask.webp');
        background-position: center;
        width: 100%;
        height: 145px;
        position: absolute;
        left: 0;
        bottom: -2px;
        z-index: 2;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    #destaque::before{
        content: '';
        display: block;
        background-image: url('https://cdn.assec.app/repo/amcb/website/static/assets/img/banner-mask.webp');
        background-position: center;
        width: 100%;
        height: 145px;
        position: absolute;
        left: 0;
        top: -2px;
        z-index: 2;
    }
/* end DESTAQUE */

/* NOTÝCIAS */
	#insights .swiper, #noticias .swiper {
		padding: 0.5rem;
	}
    #insights .swiper-wrapper, #noticias .swiper-wrapper {
		margin: 0.5rem auto;
		align-items: flex-start !important;
		display: -ms-flexbox!important;
		/*justify-content: center;*/
		flex-wrap: nowrap;
		flex-direction: row;
	}
/* end NOTÝCIAS */

/* INFORMAÇÕES DE APOIO */
    .card-header {
        padding: .75rem 1.25rem;
        margin-bottom: 0;
        background-color: #fafafa;
        background-color: var(--gray-light);
        border-bottom: 0px;
    }
/* end INFORMAÇÕES DE APOIO */

/* TESTEMUNHOS */
    .swiper-pagination-bullet-active{
        background-color: #444444;
        background-color: var(--body);
        /*margin: 0 5px;*/
    }
    .swiper-pagination-bullet{
        margin: 0 2px;
    }
    /*#opinion .swiper-slide{
        background-color: var(--white);
    }*/

    .swiper-wrapper{
		align-items: center;
    }
    /*#opinion {
    	background-color: var(--blue-dark);
    }*/

    /*section#insights, section#opinion {
		padding: 60px 0;
		overflow: hidden;
		position: relative;
	}

	#insights .section-bg, #opinion .section-bg {
		padding: 6rem 0;
	}
	#insights .section-bg {
		padding-bottom: 0;
	}
	#opinion .section-bg {
		padding-top: 0;
	}
	#insights .section-bg:before, #opinion .section-bg:before {
		content: "";
		background: var(--gray-light);
		position: absolute;
		bottom: 60px;
		top: 60px;
		left: 0;
		right: 0;
		transform: skewY(-3deg);
	}
	#opinion .section-bg:before {
		background: var(--blue-dark);
	}*/


/* end TESTEMUNHOS */


/* SECTIONS */
	/*section#project, section#parceiros {
		margin: 5vh 0;
	}*/
	section#insights {
		background-color: #fafafa;
		background-color: var(--gray-light);
	}
/* end SECTIONS */

/* NEWSLETTER */
	/*#newsletter .form-control{
		width: 100%;
	}*/
	#newsletter .btn-tertiary {
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.75px;
		border-radius: 0 0.25rem 0.25rem 0;
		padding-left: 20px;
		padding-right: 20px;
	}
	#newsletter .form-control {
		border-right: transparent;
		/*height: calc(1.5em + 0.75rem + 8.5px);*/
	}
	#newsletter label {
		font-size: small;
	}
	#newsletter a {
		font-size: small;
		color: #adadad;
	}
/* end NEWSLETTER */

/* SEARCH */
    .limit-lines {
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
/* end SEARCH */


/* PAGINATION */
    .pagination {
        margin: 15px auto;
        display: flex;
        list-style: none;
        outline: none;
    }
    .pagination > .active > a{
        background-color: #444444;
        background-color: var(--body);
        border-color: #444444;
        border-color: var(--body);
        color: #fff;
    }
    .pagination > li > a{
        /*border: 2px solid var(--body);*/
        padding: 5px 10px;
        margin: auto 5px;
        outline: none;
        cursor: pointer;
    }
    .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
        background-color: #013b5a;
        background-color: var(--blue-dark);
        border-color: #013b5a;
        border-color: var(--blue-dark);
        outline: none;
    }
    .pagination > li > a, .pagination > li > span{
        color: #444444;
        color: var(--body);
    }
    .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
        border-radius: unset;
    }
/* end PAGINATION */

/* COOKIE */
    .containerCookie {
    	font-size: 0.85rem;
        font-weight: 500;
        /*bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;*/
        background: #013b5a !important;
        background: var(--blue-dark) !important;
        justify-content: center !important;
    }

    .messageCookie {
        text-decoration: none;
        text-align: center;
        padding: 10px 20px;
        display: block;
        color: #FFFFFF;
        color: var(--white);
    }

    .messageCookie p {
        margin: 0;
    }

    .messageCookie a {
        color: #7f9dac;
        color: var(--blue-light);
        text-decoration: none;
        padding-bottom: 2px;
        border-bottom: rgba(127, 157, 172, 0) solid 1px;
        transition: all .25s ease-out;
    }
    .messageCookie a:hover {
        border-bottom: rgba(127, 157, 172,.5) solid 1px;
    }

    .btn-cookies {
        color: #FFFFFF !important;
        color: var(--white) !important;
        background-color: #79AA21  !important;
        background-color: var(--secondary)  !important;
        /*border: 2px solid var(--white)  !important;
        border-radius: 30px !important;*/
        transition: all .25s ease-out;
    }

    .btn-cookies:hover, .btn-cookies:active, .btn-cookies.active, .open > .dropdown-toggle.btn-cookies,
    .btn-cookies:focus, .btn-cookies.focus{
        color: #013b5a !important;
        color: var(--blue-dark) !important;
        background-color: #FFFFFF !important;
        background-color: var(--white) !important;
        outline: none !important;
        /*border-color: var(--secondary)  !important;*/
    }
/*end COOKIE */
	.card {
		/*transition: .5s;*/
		border: 1px solid rgba(0,0,0,.05);
		/*box-shadow: 0px 2px 12px rgb(0 0 0 / 8%);*/
	}
	/*.card:hover {}*/


/*#project p, #destaque p, #opinion p {
	font-size: 16px;
}*/



/* NEWS */
	.div-categories {
		display: inline-flex;
		justify-content: flex-end;
		align-items: center;
	}
	.pill-tag {
		font-size: 10px;
		text-transform: uppercase;
		letter-spacing: 1px;
		padding-left: 0.25rem;
	}
	.pill-tag:first-child {
		padding-left: 0.25rem;
	}
	.pill-tag:not(:last-child):after {
		content: ', ';
	}
	.pill-tag::first-child:before{
		content: '\F5B0';
	}
	.div-abstract {
		font-size: 1.25rem;
	}

	.card-body{
		padding: 2.14rem;
	}
	/*.card a {
		color: inherit;
	}*/
	.card {
		border-radius: 0;
	}
	.card a:hover {
		text-decoration: none;
	}
	.card-footer {
		background-color: #fff;
		padding-right: 2.14rem;
		padding-left: 2.14rem;
	}
	.routes-cards .card-footer {
		background-color: rgba(0,0,0,.03);
	}
	.bi-tag::before {
		-webkit-transform: rotate(90deg);
		        transform: rotate(90deg);
	}
/* end NEWS */

/* PAGE 404 */
	#notfound {
		position: relative;
		height: 100vh;
	}
	#notfound .notfound {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	.notfound {
		max-width: 700px;
		width: 100%;
		line-height: 1.4;
		text-align: center;
		padding: 15px;
	}
	.notfound .notfound-404 {
		position: relative;
		height: 220px;
	}
	.notfound .notfound-404 h1 {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		font-size: 186px;
		font-weight: 100;
		margin: 0px;
		background: linear-gradient(130deg, #79AA21, #567D26);
		background: linear-gradient(130deg, var(--secondary), var(--primary));
		color:transparent;
		-webkit-background-clip: text;
		background-clip: text;
		text-transform: uppercase;
	}
	.notfound h2 {
		font-size: 34px;
		text-transform: uppercase;
		margin-top: 0px;
		margin-bottom: 25px;
		letter-spacing: 3px;
		font-weight: 300;
	}
	.notfound p {
		margin-top: 0px;
		margin-bottom: 25px;
	}
	.notfound a {
		color: #79AA21;
		color: var(--secondary);
		text-decoration: none;
		border-bottom: 1px dotted #567D26;
		border-bottom: 1px dotted var(--primary);
		border-radius: none;
	}
	.notfound a:hover {
		color: #567D26;
		color: var(--primary);
		border-bottom: 1px solid #567D26;
		border-bottom: 1px solid var(--primary);
	}
	.notfound-social>a {
		display: inline-block;
		height: 40px;
		line-height: 40px;
		width: 40px;
		font-size: 14px;
		color: #79AA21;
		color: var(--secondary);
		border: 1px solid #efefef;
		border-radius: 50%;
		margin: 3px;
		transition: 0.2s all;
	}
	.notfound-social>a:hover {
		color: #fff;
		background-color: #ff6f68;
		border-color: #ff6f68;
	}
	@media only screen and (max-width: 480px) {
		.notfound .notfound-404 {
			position: relative;
			height: 168px;
		}
		.notfound .notfound-404 h1 {
			font-size: 142px;
		}
		.notfound h2 {
			font-size: 22px;
		}
	}
/* end PAGE 404 */


	#insights .insight-item, #noticias .noticia-item, .insights-cards .card, .news-cards .card {
		line-height: 1.35;
		box-sizing: content-box;
		min-height: 200px;
		box-shadow: 0px 2px 12px rgb(0 0 0 / 8%);
		position: relative;
		background: #fff;
		overflow: hidden;
	}

	#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image{
		/*width: 284.75px;
		height: 284.75px;*/
		background-repeat: no-repeat;
		background-color: #fafafa;
		background-color: var(--gray-light);
		/*background-color: red;
		max-height: 50vh;
		min-height: 250px;
		width: 100%;*/
		object-fit: cover;
		object-position: 50% 50%;
		aspect-ratio: auto 3 / 2;
	}

	#insights .insight-img img, #noticias .noticia-img img, .insights-cards .card .div-image, .news-cards .card .div-image {
		min-height: 100%;
		object-fit: cover;
		aspect-ratio: 3/2;
	}

/* LIST */
	/*.div-content {}*/
	.img-list, .img-list2, .img-banner, .img-split {
		background-size: cover;
		background-position: center center;
		width: 350px;
		height: auto;
		aspect-ratio: auto 1 / 1;
		overflow: hidden;
		/*border: 1rem solid var(--white);*/

	}
	.img-banner {
		aspect-ratio: initial;
		width: auto;
		width: initial;
	}
	.img-split {
		aspect-ratio: 9 / 16;
		max-width: 25vw;
	}
	.img-list img, .img-list2 img, .img-banner img, .img-split img, #accordionContent .card-body img{
		display: block;
		width: 100%;
		height: 100%;  
		object-fit: cover;
		cursor: zoom-in;
	}
	#accordionContent .card-body img {
		background-size: cover;
		background-position: center center;
		width: 350px;
		height: auto;
		aspect-ratio: auto 1 / 1;
		overflow: hidden;
	}
	.img-list a img, .img-list2 a img, .img-banner a img, .img-split a img, #accordionContent .card-body a img {
		opacity: 1;
		transition: opacity 0.35s, -webkit-transform 0.35s;
		transition: opacity 0.35s, transform 0.35s;
		transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
		-webkit-transform: scale3d(1,1,1);
		        transform: scale3d(1,1,1);
	}
	.img-list a img:hover, .img-list2 a img:hover, .img-banner a img:hover, .img-split a img:hover, #accordionContent .card-body a img:hover {
		opacity: 0.85;
		-webkit-transform: scale3d(1.025,1.025,1);
		        transform: scale3d(1.025,1.025,1);
	}

	.hover-animate:focus, .hover-animate:hover {
		-webkit-transform: translate3d(0,-2px,0);
		        transform: translate3d(0,-2px,0);
	}

	.hover-animate {
		transition: all .2s ease-in-out;
	}
/* end LIST */	

/* Media Queries */
	@media only screen and (max-width : 1920px) {}
	@media only screen and (max-width : 1680px) {}
	@media only screen and (max-width : 1400px) {}
	@media only screen and (max-width : 1200px) {}
	@media only screen and (max-width : 1180px) {}
	@media only screen and (max-width : 992px) {}
	@media only screen and (max-width : 820px) {}
	@media only screen and (max-width : 768px) {
		.img-list, .img-list2, .img-banner, .img-split{
			aspect-ratio: auto;
			/*box-shadow: none !important;*/
			width: 95%;
			margin-bottom: 3rem;
		}
		blockquote {
			width: auto;
			margin: 1.5rem auto;
		}


		.content-list .col-image .img-banner {
			display: none;
		}
		.content-list .col-image .img-banner:first-child {
			display: block !important;

		}
	}
	@media only screen and (max-width : 576px) {}
/* end Media Queries */

/* Media Print */
	@media print {
		* {
			background: transparent;
			color: #000;
			text-shadow: none; 
			-webkit-filter: none; 
			        filter: none;
			-ms-filter: none;
			-webkit-print-color-adjust: exact;
			print-color-adjust: exact;
		}
		header, footer, aside, form {
			display: none;
		}
		.page-break	{ display: block; page-break-before: always; }

		.content-list .col-image .img-banner {
			display: none;
		}
		.content-list .col-image .img-banner:first-child {
			display: block !important;
			page-break-inside: avoid;
		}
		img {
			max-width: 100% !important;
			page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
			page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
			page-break-inside: avoid; /* or 'auto' */
			page-break-inside: avoid;
			-webkit-column-break-inside: avoid;
			        break-inside: avoid;
		}
		ul, img {
			page-break-inside: avoid;
		}
		table, img, svg {
			page-break-inside: avoid;
			-webkit-column-break-inside: avoid;
			        break-inside: avoid;
		}

		td  { 
			page-break-inside:avoid; 
			page-break-after:auto; 
		}
		p {
		 /* if there aren't at least three lines before the page
		    break, move the element to the start of a new page. */
		    orphans: 3;
		}
	}
/* end Media Print */


/* Media Queries */

	/*@media only screen and (max-width : 1920px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 377.75px;
			height: 377.75px;
		}
	}

	@media only screen and (max-width : 1680px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 324.25px;
			height: 324.25px;
		}
	}
	@media only screen and (max-width : 1400px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 254.75px;
			height: 254.75px;
		}
	}
	@media only screen and (max-width : 1200px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 294px;
			height: 294px;
		}
	}
	@media only screen and (max-width : 1180px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 293px;
			height: 293px;
		}
	}
	@media only screen and (max-width : 992px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 366px;
			height: 366px;
		}
	}
	@media only screen and (max-width : 820px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 238.667px;
			height: 238.667px;
		}
	}
	@media only screen and (max-width : 768px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 340px;
			height: 340px;
		}
	}
	@media only screen and (max-width : 576px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 332px;
			height: 332px;
		}
	}*/
/* end Media Queries */




/* TABLES */
	.table {
		color: #444444;
		color: var(--main-color);
		width: auto;
		font-size: small;
		margin: 2rem auto;
		display: contents;
	}
	.table-hover tbody tr:hover {
		color: #444444;
		color: var(--main-color);
		background-color: #fafafa;
		background-color: var(--gray-light);
	}
/* end TABLES */


/*CARDS EFFECTS*/
	.hoverflowhidden {
		overflow: hidden;
	}

	.card-columns {
		/*-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;*/
		-webkit-column-gap: 1.25rem;
		-moz-column-gap: 1.25rem;
		grid-column-gap: 15px;
		-webkit-column-gap: 15px;
		        column-gap: 15px;
		orphans: 1;
		widows: 1;
	}

	.react-pdf__Page__textContent {
		display: none;
	}
/* end CARDS EFFECTS*/

/* FANCYBOX */
	.fancybox__thumbs .carousel__slide .fancybox__thumb::after {
		border-width: 4px !important;
		border-color: #DF760E !important;
		border-radius: 0 !important;
	}
	.fancybox__image, .fancybox__thumb {
		background-color: #FFFFFF !important;
		background-color: var(--white) !important;
	}

/* end FANCYBOX */

#opinion .swiper-container .swiper-scrollbar {
	display: none;
}

#opinion blockquote p {
	margin-bottom: 0.5rem !important;
}

#partners-cards .partner-item,
#companies-cards .company-item{
	min-height: 0 !important;
	min-height: initial !important;
}


@media only screen and (max-width : 576px) {
	#opinion blockquote {
		padding: 20px;
		margin: 10px;
	}
	#opinion blockquote:before {
		display: none;
	}
}
footer {
	background: var(--gray-light);
	font-weight: 500;
}

footer a {
	color: var(--main-color);
	text-decoration: none;
	/*padding-bottom: 2px;
	margin-bottom: 4px;*/
	border-bottom: rgba(255, 255, 255, 0) solid 1px;
	transition: all .25s ease-out;
}
footer a:hover {
	color: var(--primary);
	text-decoration: underline;
	border-bottom: rgba(255, 255, 255,.5) solid 1px;
}

footer a i:hover {
	text-decoration: none;
}
footer h5 {
	font-size: 1rem;
	font-weight: 600;
	line-height: inherit;
}
footer h6 {
	/*font-weight: 400;*/
	line-height: inherit;
	font-size: 0.875rem;
}
footer .address a {
	display: inline-block;
}
/*footer .address {
	cursor: default;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}
footer .h6, footer h6 {
    font-size: 1.875rem;
	font-weight: bold;
	line-height: 1.5;
}

footer span, footer a {
	font-size: 0.875rem;
	display: flex;
	align-items: baseline;
}
footer a:hover {
    text-decoration: none;
    border-bottom: rgba(255, 255, 255,0) solid 1px;
}

footer .redes a:hover {
	text-decoration: none;
    border-bottom: rgba(255, 255, 255,0) solid 1px;
    transform: scale(1.025);
    filter: drop-shadow(2px 2px 2px #011f2f);
}*/

footer .social-media {
	display: flex;
	/*justify-content: flex-start;
	column-gap: 15px;*/
}

footer span.logosfooter {
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
}
/*span.logos, footer span.logosfooter.redes {
    justify-content: flex-start;
    column-gap: 15px;
}*/
footer .copyright span {
    font-size: 12px;
    /*display: inherit;*/
}

.current_bottom{
    text-decoration: underline;
}

img.light-logo.svg-logo.img-fluid {
	-webkit-filter: brightness(0) invert(1);
	        filter: brightness(0) invert(1);
}

.bg-color-body{
    background-color: var(--blue-dark);
}
.bg-color-dark{
    background-color: var(--gray-dark);
}

.footer-bar {
	background-color: var(--gray-dark);
	/* font-size: .75rem; */
	align-items: center;
}

.footer-bar .copyright li span {
    font-size: 12px;
    /*display: inline-flex;
    align-items: center;*/
}

.footer-bar a {
	color: #e03a3c;
	padding-bottom: 0;
    margin-bottom: 0;
	border-bottom: rgba(255, 255, 255, 0) solid 1px;
}
.footer-bar a:hover {
	border-bottom: rgba(255, 255, 255, 0) solid 1px;
}

/*.copyright {
	line-height: 1.25;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}*/


@media print {
	.footer-bar {
		position: fixed;
		bottom: 0;
		width: 100%;
		border-top: 1px solid transparent; /* for demo */
		margin-top: 30px;
	}
}
/* #navbar .navbar{
   background-color: white;
   box-shadow: rgb(0, 0, 0, 0.2) 0px 2px 2px;
} */

.navbar-nav {
    align-items: baseline;
    flex-wrap: wrap;
}

.navbar-expand-lg .navbar-nav{
    grid-column-gap: 1.5vw;
    grid-column-gap: 1.5vw;
    -webkit-column-gap: 1.5vw;
            column-gap: 1.5vw;
}
#navbar a{
    color: var(--body);
    font-size: 16px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}
#navbar .dropdown-menu a{
    font-size: 14px;
}
#navbar .current_top {
    color: var(--secondary);
    text-decoration: underline;
}

/*#navbar a:last-child {
    padding-right: 0;
}*/
#navbar .menu_superior {
    /*padding: 5px 0;*/
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#navbar .menu_superior a {
    font-size: 12px;
    line-height: 0;
    padding: 0 8px;
    font-weight: 400;
}
#navbar .menu_superior a:last-child {
    padding-right: 0;
}
.button_no_border{    
    border: 0px;
    background:transparent;
    cursor: pointer;
}
.button_no_border:focus{    
    border: 0px;
    outline: 0px;
}
.navbar-light .navbar-toggler{
    border: 0;
}

.navbar-light .navbar-toggler:focus{
    outline: 0;
}
#navbar a:hover{
    color: var(--secondary);
}

.dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover {
	background-color: var(--gray-light);
}
#navbar a.navbar-brand {
    padding-bottom: 0px;
    border-bottom: 0px solid transparent;
    padding: 0.625rem 1rem;
}
#navbar a.navbar-brand:hover{
    padding-bottom: 0px;
    border-bottom: 0px solid transparent;
}
.svg-logo{
    height: 100px;
    padding: 0.5rem 0;
    transition: all 0.7s ease-in-out;
}

.scroll-navbar .svg-logo{
    height: 56px;
    padding: 0;
    transition: all 0.7s ease-in-out;
}
.navbar-nav{
    transition: all 0.7s ease-in-out;

}

.scroll-navbar .navbar-nav{
    transition: all 0.7s ease-in-out;
}
.top {
    height: 35px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: all 0.7s ease-in-out;
    background-color: var(--gray-light);
}
.scroll-navbar .top{
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    height: 0;
    background-color: transparent;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    transition: all 0.7s ease-in-out;
}
/*.top-bar {
    top: 0;
    height: auto;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}
.scroll-navbar .top-bar{
    position: absolute;
    top: -50px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}*/

/*#small-logo{
    display: none;
}*/
.svg-logo-small{
    height: 45px;
}

/* .nav-effect{
    -webkit-transition: background 1.5s;
    -moz-transition: background 1.5s;
    -ms-transition: background 1.5s;
    -o-transition: background 1.5s;
    transition: background 1.5s;
} */

.sticky-top .nav-effect{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px;
}

.h-0-transition{
    height: 0;
    transition: all 2s ease-in-out;
}

.dropdown-toggle:after { content: none }


.dropdown-menu {
    border: transparent;
    border-radius: 0;
}

.dropdown-menu.show {
    display: block;
    top: calc(100%);
    padding: 10px 0;
    z-index: 99;
    background: #fff;
    box-shadow: 0px 0px 10px rgb(127 137 161 / 25%);
    transition: 0.3s;
    margin-top: 0;
}

/* a#navbarDropdown {
    padding-bottom: 0.875rem;
} */

@media only screen and (min-width : 992px) {

    /*open dropdown-menu on hover (>992px)*/
    #navbar .navbar-expand-lg .dropdown:hover > .dropdown-menu {
        display: block;
        top: calc(100%);
        padding: 10px 0;
        z-index: 99;
        background: #fff;
        box-shadow: 0px 0px 10px rgb(127 137 161 / 25%);
        transition: 0.3s;
        margin-top: 0;
    }

}

@media (max-width: 1024px) {
    .navbar-expand-lg .navbar-nav{
        grid-column-gap: 15px;
        -webkit-column-gap: 15px;
                column-gap: 15px;
    }
    #navbar .menu_superior a {
        font-size: small;
    }
    /*#navbar a {
        font-size: 14px;
    }*/
	.svg-logo {
		height: 75px;
	}
}

@media (max-width: 992px) {
    #navbar .menu_superior {
        justify-content: flex-end;
    }
    .navbar-collapse{
        background-color: var(--white);
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

	.dropdown-menu.show {
		top: calc(100%);
		background: transparent;
		box-shadow: none;
        padding-top: 0;
	}
    a#navbarDropdown {
        padding-bottom: 0.5rem;
    }
}

@media (max-width: 820px)  {
    #navbar .navbar-nav a {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }
    #navbar .navbar-nav {
        padding-bottom: 1rem;
    }
    #navbar .dropdown-menu a {
        font-size: 0.875rem;
        }
    }

@media (min-width: 992px){
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0;
		padding-left: 0;
	}
}

.bi::before, [class^="bi-"]::before, [class*=" bi-"]::before {
    vertical-align: -0.25em;
}
textarea{
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:focus, textarea:focus {
    color: #495057;
    background-color: #fff;
    border-color: #495057;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(73,80,87,.25);
}

/* APP FORM */
#app_form .control-label{
    padding: 0 10px;
    position: absolute;
    top: -6px;
    background-color: white;
    left: 30px;
    font-size: 0.7rem;
    font-weight: 700;
    z-index: 100;
}
.custom-file-label {
    width: 98%;
    border-color: #ced4da; 
    cursor: pointer;
    left: 15px;
}
.custom-file-label:after {
    content: "Carregar";
    border-radius: 0;
    border-color: gray;
    color: #FFFFFF;
    background-color: gray;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: .75px;
}

#app_form button.remove-file{
    border: 2px solid var(--body);
    border-radius: 50%;
    background-color: white;
    font-size: 11px;
}

.overflow-input{
    inline-size: 98%;
    overflow: hidden;
    /* top: 0px; */
    padding-top: 8px;
}


#modal_form .btn-send, #modal_form .btn-cancel {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.75px;
    transition: all 0.3s ease 0s;
    display: inline-flex;
    justify-content: center;
    align-items: baseline;
    margin: 0;
    padding: .375rem .75rem .375rem 1.25rem;
    color: var(--gray-light);
    background-color: var(--blue-dark);
}

#modal_form .btn-cancel {
    color: var(--main-color);
    background-color: var(--gray-light);
}

#modal_form .btn-send:hover {
    text-decoration: none;
    background-color: #00283c;
    box-shadow: 0 .125rem 0.5rem rgba(31, 31, 31, 0.25)!important;
}
#modal_form .btn-cancel:hover {
    text-decoration: none;
    background-color: #f8f9fa;
    box-shadow: 0 .125rem 0.5rem rgba(0,0,0,.15)!important;
}

#modal_form .btn > .bi.bi-chevron-right, #modal_form .btn > .bi.bi-x-lg {
    margin-left: 5px;
    color: var(--orange-dark);
    transition: all 0.4s ease-out;
}

.modal-footer {
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
            column-gap: 1em;
}
.div-preview {
    position: fixed; 
    z-index: 10000;
    width: 100%;
    background-color: #009ef7 !important;
    color: #fff;
}

/* BREADCRUMB (desativado)*/
    .breadcrumb {
        font-size: 12px;
        background-color: var(--gray-light);
    }
    .breadcrumb-item a{
        color: #6c757d;
    }
    ul.breadcrumb {
        padding: 10px 16px;
        list-style: none;
    }
    ul.breadcrumb li {
        display: inline;
    }
    ul.breadcrumb li+li:before {
        padding: 8px;
        color: black;
        content: "/\00a0";
    }
    ul.breadcrumb li a {
        color: #0275d8;
        text-decoration: none;
    }
    ul.breadcrumb li a:hover {
        color: #01447e;
        text-decoration: underline;
    }
    .breadcrumb-item.active {
        color: #6c757d;
        font-weight: 600;
    }
    .breadcrumb-item+.breadcrumb-item::before {
        float: left;
        padding-right: 0.5rem;
        color: var(--secondary);
        content: ">";
    }
/* end BREADCRUMB */

/* BOOTSTRAP BREADCRUMB*/

/* end BOOTSTRAP BREADCRUMB*/
textarea{
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:focus, textarea:focus {
    color: #495057;
    background-color: #fff;
    border-color: #495057;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(73,80,87,.25);
}
@media (max-width: 576px){
    .modal-dialog {
        max-width: 320px;
        margin: 0.5rem auto;
    }
}

/* APP FORM */
/*
#app_form .control-label{
    padding: 0 10px;
    position: absolute;
    top: -6px;
    background-color: white;
    left: 30px;
    font-size: 0.7rem;
    font-weight: 700;
    z-index: 100;
}
.custom-file-label {
    width: 98%;
    border-radius: 0;
    border-color: var(--blue-light);
    cursor: pointer;
    left: 15px;
}
.custom-file-label:after {
    content: "Carregar";
    border-radius: 0;
    border-color: var(--blue-light);
    color: #FFFFFF;
    background-color: var(--blue-light);
    cursor: pointer;
}

#app_form button.remove-file{
    border: 2px solid var(--body);
    border-radius: 50%;
    background-color: white;
    font-size: 11px;
}

.overflow-input{
    inline-size: 98%;
    overflow: hidden;
    top: 0px;
    padding-top: 8px;
}
*/


/*.normal .content-body .div-image {
    max-height: 40vh;
    overflow: hidden;
}

.list .div-text, .list2 .div-text {
    display: flow-root;
}*/

/* BLOCKQUOTE */
	blockquote{		
        font-size: 1.125em;
        width: auto;
        margin: 1rem calc(16px + 1rem);
        font-style: italic;
        padding: 1.2em 1.2em 1.2em 3.25em;
        /* border-left: 8px solid var(--secondary); */
        line-height: 1.6;
        background: var(--gray-light);
        inline-size: -moz-fit-content;
        inline-size: -webkit-fill-available;
        inline-size: -webkit-fit-content;
        inline-size: fit-content;
        position: relative;
	}

	blockquote::before{
        font-family: Arial;
        content: "\201C";
        color: var(--primary);
        font-size: 4em;
        position: absolute;
        left: 10px;
        top: -10px;
	}

	blockquote::after{
		content: '';
	}

	blockquote span.legenda{
		font-size: smaller;
		display:block;
		font-style: normal;
		font-weight: bold;
		margin-top:1em;
	}

	blockquote p {
		margin-bottom: 0;
	}

	@media only screen and (max-width : 768px) {
		blockquote {
			width: auto;
			margin: 1.5rem auto;
		}
	}
/* end BLOCKQUOTE */

#insights .insight-item, #news-cards .new-item, .insights-cards .card, .news-cards .card {
    box-sizing: content-box;
    min-height: 200px;
    box-shadow: 0px 2px 12px rgb(0 0 0 / 8%);
    position: relative;
    background: #fff;
    overflow: hidden;
}

#insights .insight-item .insight-img, #news-cards .new-item .new-img, .insights-cards .card .div-image, .news-cards .card .div-image {
    background-repeat: no-repeat;
    background-color: var(--gray-light);
    object-fit: cover;
    object-position: 50% 50%;
}

#insights .insight-item .insight-img img, #news-cards .new-item .new-img img, .insights-cards .card .div-image img, .news-cards .card .div-image img{
    object-fit: cover;
    aspect-ratio: 3/2;
}

#insights-cards .card-title, #news-cards .card-title {
    color: var(--main-color);
    font-weight: 800;
    font-size: 18px;
    line-height: 1.35;
}
#partners-cards .card-title, #gallery-cards .card-title, #gallery .card-title, #companies-cards .card-title{
    font-size: small;
    color: #54595f;
    line-height: 1.35;
    font-weight: 500;
    letter-spacing: 0.25px;
}

#partners-cards .card-title::before, #gallery-cards .card-title::before, #companies-cards .card-title::before {
    height: 2px;
    width: 3rem;
    content: "";
    display: block;
    background-color: var(--secondary);
    margin: auto;
    margin-bottom: 20px;
}
#gallery-cards .card-title::before {
    background-color: transparent;
}


#partners-cards .partner-item, #gallery-cards .gallery-item, #companies-cards .company-item {
    box-sizing: content-box;
    min-height: 200px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    position: relative;
    background: #fff;
    overflow: hidden;
}

#partners-cards .partner-item .partner-img, #gallery-cards .gallery-item .gallery-img, #companies-cards .company-item .company-img {
    background-repeat: no-repeat;
    object-fit: cover;
    object-position: 50% 50%;
    aspect-ratio: auto 4 / 3;
}

#partners-cards .partner-item .partner-img img, #gallery-cards .gallery-item .gallery-img img, #companies-cards .company-item .company-img img {
    min-height: 100%;
    object-fit: cover;
}
#partners-cards .hover-animate:focus,
#partners-cards .hover-animate:hover,
#gallery-cards .hover-animate:focus,
#gallery-cards .hover-animate:hover,
#companies-cards .hover-animate:focus,
#companies-cards .hover-animate:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

#insights-cards .card-text p, #news-cards .card-text p {
    margin-bottom: 0;
    font-size: 0.875em;
}
#insights-cards .card-text, #news-cards .card-text {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.insights-cards .titulo-pp::after,
.news-cards .titulo-pp::after,
.partners-cards .titulo-pp::after,
.gallery-cards .titulo-pp::after,
.companies-cards .titulo-pp::after {
    margin-bottom: 2.5rem;
}
.card-body {
    padding: 1.25rem;
}
.card-footer {
    padding-right: inherit;
    padding-left: inherit;
}

.partners-cards .card-body,
.gallery-cards .card-body,
.companies-cards .card-body,
#companies-cards .card-body {
    padding: 0 1.25rem;
}
.partners-cards .card-footer,
.gallery-cards .card-footer,
.companies-cards .card-footer {
    border: transparent;
    background-color: transparent;
}

.routes-cards .card-footer {
    background-color: rgba(0,0,0,.03);
    border-top: 1px solid rgba(0,0,0,0);
}

.routes-cards .hover-animate:focus, .routes-cards .hover-animate:hover {
    -webkit-transform: translate3d(0,-2px,0);
            transform: translate3d(0,-2px,0);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}
/*.div-image {
    cursor: zoom-in;
}*/
.accordion .content-body .div-image img, .list .content-body .div-image img, .list2 .content-body .div-image img, .normal .content-body .div-image img {
    max-height: 50vh;
    /*min-height: 350px;*/
    width: 100%;
    object-fit: cover;
    object-position: 85% 50%;
    cursor: zoom-in;
}
.normal .content-body .div-image img {
    cursor: default;
}

/*.accordion .div-image, .list .div-image, .list2 .div-image, .normal .div-image {
    border: 1rem solid var(--white);
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}*/

.content-body .div-content .title {
    color: var(--cinza-escuro);
    font-weight: 400;
    font-size: 1.20rem;
    text-transform: uppercase;
    position: relative;
    padding-left: 60px;
}

.content-body .div-content .title:after {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 5px;
    width: 3rem;
    background-color: var(--laranja);
    content: "";
}

.content-body .iso-nav button {
    border-radius: 0;
}

.content-body .iso-nav button.active,
.content-body .iso-nav button:hover {
    background-color: var(--cinza-escuro) !important;
    border-color: var(--cinza-escuro) !important;
    outline: none !important;
    color: #FFF !important;
}

.content-body .div-scroll div {
    position: absolute;
    bottom: 20px;
    left: 50%;
    padding-top: 60px;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: var(--cinza);
    text-decoration: none;
    transition: opacity .3s;
}

.content-body .div-scroll div:hover {
    opacity: 0.5;
}

.content-body .div-scroll div span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 30px;
    height: 50px;
    margin-left: -15px;
    border: 2px solid var(--cinza);
    border-radius: 50px;
    box-sizing: border-box;
}

.content-body .div-scroll div span:before {
    position: absolute;
    top: 10px;
    left: 50%;
    content: '';
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: var(--cinza);
    border-radius: 100%;
    -webkit-animation: scroll-dott 2s infinite;
    animation: scroll-dott 2s infinite;
    box-sizing: border-box;
}

.content-body .div-items .item {
    width: 23%;
    padding: 1%;
    float: left;
}

.content-body .div-items .loading {
    clear: both;
}

.content-list-item {
    border-top: 1px solid #dee2e6;
}


@-webkit-keyframes scroll-dott {
    0% {
        -webkit-transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        -webkit-transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@keyframes scroll-dott {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        -webkit-transform: translate(0, 20px);
                transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}


/* ACCORDION PAGE */




.element{
    float: right;
    justify-content: center;
    shape-margin: 7em;
}

.accordion .accordion-title {
    display: block;
    position: relative;
    font-size: 1.125rem;
    /*line-height: 24px;*/
    font-weight: 700;
    padding-right: 5px;
    cursor: pointer;
}

main.accordion .card-header {
    background-color: var(--gray-light);
    border-bottom: 2px dotted #dee2e6;
}

main.accordion .card-header button {
     color: var(--body);
    display: block;
    position: relative;
    font-size: 1em;
    line-height: 24px;
    font-weight: 400;
    padding-right: 5px;
    cursor: pointer;
}

main.accordion .card-header button .bi {
    color: var(--blue-dark);
    font-size: 1.5rem;
    transition: all 0.4s ease-in-out;
}

main.accordion .card-header button[aria-expanded="true"] .bi.bi-chevron-down {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

/* END ACCORDION PAGE */

.content-body .div-pdf-view canvas {
    width: 100% !important;
    height: auto !important;
}


/* NOTÍCIAS */
    .div-source {
        font-size: .75rem;
    }
    .div-source span:last-child {
        padding-right: 0;
    }
    /*.div-source span:nth-last-child(-n+1) {
        font-style: italic;
        color: var(--gray);
    }*/
    .div-source span:not(:last-child):after{
        padding: 0 0.5rem;
        color: var(--secondary);
        content: "|";
    }

    .div-abstract {
        font-size: 1.1em;
        word-break: break-word;
    }


    .div-gallery .subtitulo-pp{
        color: var(--main-color);
        font-weight: 700;
        font-size: 18px;
        text-align: center;
    }
    .div-gallery .subtitulo-pp::after{
        height: 3px;
        width: 4rem;
        content: "";
        display: block;
        background-color: var(--secondary);
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 1.25rem;
    }

    .div-gallery img {
        aspect-ratio: 1/1;
        object-fit: cover;
    }

    .div-last {
        position: -webkit-sticky;
        position: sticky;
        top: 6rem;
        right: 0;
        z-index: 2;
        height: -webkit-max-content;
        height: max-content;
        overflow-y: hidden;
        justify-content: center;
    }
    .div-last .subtitulo-pp{
        color: var(--main-color);
        font-weight: 700;
        font-size: 18px;
        text-align: center;
    }

    .div-last .subtitulo-pp::after{
        height: 3px;
        width: 4rem;
        content: "";
        display: block;
        background-color: var(--secondary);
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 1.25rem;
    }

    .div-last a {
        color: var(--main-color);
        font-weight: 600;
        font-size: 14px;
    }

    .div-last a:hover{
        text-decoration: none;
    }


    /*.div-last .div-image {}*/

    .div-last .div-image img {
        aspect-ratio: 16 / 9;
        object-fit: cover;
    }

    .div-last .btn-text-inv, .div-last .btn-text-inv a {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.75px;
        display: inline-flex;
        color: var(--gray-light);
        background-color: var(--main-color);
        transition: all 0.3s ease 0s;
        justify-content: center;
        align-items: baseline;
        padding: 7px 14px;
        text-decoration: none;
    }
    .div-last .btn-text-inv:hover, .div-last .btn-text-inv a:hover {color: var(--main-color);
        background-color: var(--gray-light);
    }
    .div-last .div-text{
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
    }
    .div-last .insight-item {
        background-color: #FFF;
    }
    .div-last .div-source {
        font-weight: 400;
    }

    .div-content .div-image img {
        /*aspect-ratio: 16 / 9;*/
        object-fit: cover;
    }

    .insight .div-content .div-image {
        overflow: hidden;
    }
    .insight .div-content .div-image img {
        /*cursor: zoom-in;*/
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    }


    .insight .div-content .div-image img:hover {
        -webkit-transform: scale3d(1.05,1.05,1), opacity 0.35s, transform 0.35s;
                transform: scale3d(1.05,1.05,1), opacity 0.35s, transform 0.35s;
        transition: all .2s ease-in-out;
    }


/* END NOTÍCIAS */
/* PARTNER */
    #partner .div-text p:last-child {
        margin-bottom: 0;
    }
    #partner p a {
        color: var(--main-color);
    }
    #partner p a:hover {
        color: var(--secondary);
    }
    #partner .partner-item {
        box-sizing: content-box;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
        position: relative;
        overflow: hidden;
    }
    #partner figure {
        aspect-ratio: 1/1;
        overflow: hidden;
    }
    #partner figure img {
        min-height: 100%;
        object-fit: contain;
    }
/* END PARTNER */

/* COMPANY */
    #company .div-text p:last-child {
        margin-bottom: 0;
    }
    #company p a {
        color: var(--main-color);
    }
    #company p a:hover {
        color: var(--secondary);
    }
    #company .company-item {
        box-sizing: content-box;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
        position: relative;
        overflow: hidden;
    }
    #company figure {
        aspect-ratio: 1/1;
        overflow: hidden;
    }
    #company figure img {
        min-height: 100%;
        object-fit: contain;
}
/* END PARTNER */

/* BTN e BOOTSTRAP ICONS */

    #webinar-cards .btn-text, #webinar-cards .btn-text-inv {
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: 0.75px;
        color: var(--white);
        transition: all 0.3s ease 0s;
        background-color: var(--orange);
        padding: 0.5rem 1.5rem;
        text-decoration: none;
        border-radius: 40px;
    }
    #webinar-cards .btn-text:hover {
        background-color: var(--red);
    }
    #webinar-cards .btn-text:active {
        background-color: var(--blue);
    }
    #webinar-cards .btn-text:active {
        background-color: #0062cc;
    }
    #webinar-cards .btn-text-inv {
        color: #0062cc;
        background-color: var(--white);
    }
    #webinar-cards .btn-text-inv:hover {
        color: var(--white);
        background-color: var(--blue);
    }

    .btn-inscription {
        font-size: small;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: var(--white);
        background-color: var(--red);
        padding: 0.5rem 1.5rem;
        border-radius: 0;
        transition: 0.4s;
        -webkit-transform: translateY(0);
                transform: translateY(0);
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .btn-inscription:hover, .btn-inscription:active {
        color: var(--white);
        background-color: var(--blue);
        transition : 0.4s;
        -webkit-transform : translateY(-2px);
                transform : translateY(-2px);
    }
    .btn-inscription:active{
        background-color: var(--orange);
    }

    .bi.bi-chevron-right, .bi.bi-chevron-left, .bi.bi-download {
        font-size: medium;
        font-size: initial;
        margin-left: 5px;
        line-height: 0;
        transition: all 0.4s ease-out;
    }
    .bi.bi-chevron-left {
        margin-left: 0;
        margin-right: 5px;
    }

/* end BTN e BOOTSTRAP ICONS */

/* BADGES */

    #webinar-cards .webinar-badge {
    position: absolute;
    top: 30%;
    right: 15px;
    max-width: 80px;
    }
    /* Media Queries */
    @media only screen and (max-width : 1920px) {}
    @media only screen and (max-width : 1680px) {
        #webinar-cards .webinar-badge {
            top: 34%;
            max-width: 90px;
        }
    }
    @media only screen and (max-width : 1400px) {
        #webinar-cards .webinar-badge {
            top: 27%;
            right: 10px;
            max-width: 90px;
        }
    }
    @media only screen and (max-width : 1200px) {
        #webinar-cards .webinar-badge {
            top: 21%;
            right: 5px;
            max-width: 75px;
        }
    }
    @media only screen and (max-width : 1180px) {
        #webinar-cards .webinar-badge {
            top: 22%;
            right: 5px;
            max-width: 70px;
        }
    }
    @media only screen and (max-width : 992px) {}
    @media only screen and (max-width : 820px) {
        #webinar-cards .webinar-badge {
            top: 30%;
        }
    }
    @media only screen and (max-width : 768px) {}
    @media only screen and (max-width : 576px) {
        #webinar-cards .webinar-badge {
            top: 42%;
            right: 5px;
            max-width: 100px;
        }
    }
    @media only screen and (max-width : 425px) {
        #webinar-cards .webinar-badge {
            top: 28%;
            right: 10px;
            max-width: 75px;
        }
    }
    /* end Media Queries */

/* END BADGES */

/* MEDIA QUERIE */   
    @media only screen and (max-width: 768px){
        .div-last {
            position: static;
            position: initial;
            box-shadow: none;
            height: auto;
            margin-bottom: 30px;
        }
        .div-last .insight-item {
            box-shadow: none !important;
        }
    }
/* END MEDIA QUERY */

